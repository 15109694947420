// import { combineReducers } from 'redux'

import React, { createContext, useCallback, useReducer } from 'react'
import { local as storeLocal } from '../Config/storage'
// import { combineReducers } from 'redux'

const storageReducer = (state, action) => {
    switch (action.type) {
        case 'retreive-storage':
            if (!Array.isArray(action.payload)) {
                var item = storeLocal.getItem('g2h_' + action.payload)
                return { ...state, [action.payload]: item }
            } else {
                return {
                    ...state,
                    ...action.payload.reduce((acc, key) => {
                        acc[key] = storeLocal.getItem('g2h_' + key)
                        return acc
                    }, {})
                }
            }
        // case 'save-to-storage':
        //     var val = action.payload.value
        //     val = val || ''
        //     storeLocal.setItem('g2h_' + action.payload.key, val)
        //     return { ...state, [action.payload.key]: val }
        case 'save-storage':
            var val = action.payload.value || state[action.payload.key] || ''
            state[action.payload.key] !== undefined && storeLocal.setItem(`g2h_${action.payload.key}`, val)
            return { ...state, [action.payload.key]: val }

        default:
            return state
    }
}

let withLog
if (process.env.NODE_ENV === 'development') {
    withLog = require('./withLog')
}

const combineReducers = (reducer1, reducer2) => {
    return (state, action) => reducer2(reducer1(state, action), action)
}
function combineWithLog(reducers, name) {
    reducers = reducers.length == 1 ? reducers[0] : combineReducers(...reducers)
    return process.env.NODE_ENV === 'development' ? withLog(reducers, name) : reducers
}

const Contexter = (name, reducers = [], initialState) => {
    const Context = createContext()
    const combinedReducer = combineWithLog(reducers, name)
    function Provider({ children }) {
        const memoizedReducer = useCallback(combinedReducer, reducers)
        const [state, dispatch] = useReducer(memoizedReducer, initialState)

        return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
    }
    Context.displayName = name + 'Context'
    Provider.displayName = name + 'Provider'

    const useStateDispatch = () => React.useContext(Context)

    return { Context, Provider, useStateDispatch }
}

export const withProviders = (...providers) => (WrappedComponent) => (props) =>
    providers.reduceRight((acc, prov) => {
        let Provider = prov
        if (Array.isArray(prov)) {
            Provider = prov[0]
            return <Provider {...prov[1]}>{acc}</Provider>
        }
        return <Provider>{acc}</Provider>
    }, <WrappedComponent {...props} />)

export { Contexter, storageReducer }
