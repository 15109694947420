import { Contexter, storageReducer } from './Contexter'

const initialState = {
    locale: '', // fr (ui language)
    kbLangKey: '', // he (keyboard)
    sectionKey: '', // section
    history: {},
    parsedSearch: {},
    query: null,
    mobileLoadKeyboard: false,
    facebookLoaded: false,
    facebookDisabled: false
    // mobileLoadAdsense: true
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'update-state':
            return { ...state, ...action.payload }
        case 'mobile-load-keyboard':
            return { ...state, mobileLoadKeyboard: true }
        case 'disable-facebook':
            return { ...state, facebookDisabled: action.payload }
        case 'facebook-loaded':
            return { ...state, facebookLoaded: true }
        // return state
        // case 'reset':
        //   return initialState
        // case 'increment':
        //   return { ...state, count: state.count + 1 }
        // case 'decrement':
        //   return { ...state, count: state.count - 1 }
        // case 'set-color':
        //   return { ...state, currentColor: action.payload }
        // case 'SET-KEYBOARD-LANGUAGE':
        //   return { ...state, kbLang: action.payload}
        // case 'set-section':
        //   return { ...state, section: action.payload}
        default:
            return state
    }
}

const { Provider, useStateDispatch } = Contexter('Gate2home', [reducer, storageReducer], initialState)

export { useStateDispatch as useGate2HomeContext }
export { Provider as Gate2HomeProvider }
